
import common from '@/mixin/common.js'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'r-scene',
  mixins: [common],
  data() {
    return {
      // productBgImage: require('@/assets/home/product_bg.png'),
      productBgImage: '',
    }
  },
  computed: {
    ...mapGetters('scene', ['sceneList']),
  },
  mounted() {
    // this.scrollToTop();
  },
  methods: {
    showDetail(params) {
      this.$router.push(`/scene/detail#${params}`);
    },
  },
}